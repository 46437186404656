import './Sandeep_animation.css';

const logo  = document.querySelectorAll('Sandeep-name');

const Sandeep_animation = (props) =>{
    return (
        <div className="w-48 text-center justify-self-center">
            <svg id="Sandeep-name" fill="red" viewBox="0 0 412 79" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="-0.528015" y="0.223999" width="413" height="79" fill="none">
                  <rect fill="white" x="-0.528015" y="0.223999" width="413" height="79"/>
                </mask>
                <path d="M28.608 73.672C24.192 73.672 20.224 72.904 16.704 71.368C13.248 69.768 10.528 67.592 8.54399 64.84C6.55999 62.024 5.53598 58.792 5.47198 55.144H14.784C15.104 58.28 16.384 60.936 18.624 63.112C20.928 65.224 24.256 66.28 28.608 66.28C32.768 66.28 36.032 65.256 38.4 63.208C40.832 61.096 42.048 58.408 42.048 55.144C42.048 52.584 41.344 50.504 39.936 48.904C38.528 47.304 36.768 46.088 34.656 45.256C32.544 44.424 29.696 43.528 26.112 42.568C21.696 41.416 18.144 40.264 15.456 39.112C12.832 37.96 10.56 36.168 8.63999 33.736C6.78399 31.24 5.85599 27.912 5.85599 23.752C5.85599 20.104 6.78399 16.872 8.63999 14.056C10.496 11.24 13.088 9.064 16.416 7.528C19.808 5.992 23.68 5.224 28.032 5.224C34.304 5.224 39.424 6.792 43.392 9.928C47.424 13.064 49.696 17.224 50.208 22.408H40.608C40.288 19.848 38.944 17.608 36.576 15.688C34.208 13.704 31.072 12.712 27.168 12.712C23.52 12.712 20.544 13.672 18.24 15.592C15.936 17.448 14.784 20.072 14.784 23.464C14.784 25.896 15.456 27.88 16.8 29.416C18.208 30.952 19.904 32.136 21.888 32.968C23.936 33.736 26.784 34.632 30.432 35.656C34.848 36.872 38.4 38.088 41.088 39.304C43.776 40.456 46.08 42.28 48 44.776C49.92 47.208 50.88 50.536 50.88 54.76C50.88 58.024 50.016 61.096 48.288 63.976C46.56 66.856 44 69.192 40.608 70.984C37.216 72.776 33.216 73.672 28.608 73.672Z" stroke={props.stroke_color1} strokeWidth={props.stroke_size} mask="url(#path-1-outside-1)"/>
                <path d="M103.288 58.12H74.1037L68.7277 73H59.5117L83.7037 6.472H93.7837L117.88 73H108.664L103.288 58.12ZM100.792 51.016L88.6957 17.224L76.5997 51.016H100.792Z" stroke={props.stroke_color1} strokeWidth={props.stroke_size} mask="url(#path-1-outside-1)"/>
                <path d="M181.031 73H172.295L137.159 19.72V73H128.423V5.992H137.159L172.295 59.176V5.992H181.031V73Z" stroke={props.stroke_color1} strokeWidth={props.stroke_size} mask="url(#path-1-outside-1)"/>
                <path d="M216.755 6.088C224.051 6.088 230.355 7.464 235.667 10.216C241.043 12.904 245.139 16.776 247.955 21.832C250.835 26.888 252.275 32.84 252.275 39.688C252.275 46.536 250.835 52.488 247.955 57.544C245.139 62.536 241.043 66.376 235.667 69.064C230.355 71.688 224.051 73 216.755 73H195.923V6.088H216.755ZM216.755 65.8C225.395 65.8 231.987 63.528 236.531 58.984C241.075 54.376 243.347 47.944 243.347 39.688C243.347 31.368 241.043 24.872 236.435 20.2C231.891 15.528 225.331 13.192 216.755 13.192H204.659V65.8H216.755Z" stroke={props.stroke_color1} strokeWidth={props.stroke_size} mask="url(#path-1-outside-1)"/>
                <path d="M272.534 13.192V35.56H296.918V42.76H272.534V65.8H299.798V73H263.798V5.992H299.798V13.192H272.534Z" stroke={props.stroke_color1} strokeWidth={props.stroke_size} mask="url(#path-1-outside-1)"/>
                <path d="M321.753 13.192V35.56H346.137V42.76H321.753V65.8H349.017V73H313.017V5.992H349.017V13.192H321.753Z" stroke={props.stroke_color1} strokeWidth={props.stroke_size} mask="url(#path-1-outside-1)"/>
                <path d="M406.972 25.672C406.972 31.24 405.052 35.88 401.212 39.592C397.436 43.24 391.644 45.064 383.836 45.064H370.972V73H362.236V6.088H383.836C391.388 6.088 397.116 7.912 401.02 11.56C404.988 15.208 406.972 19.912 406.972 25.672ZM383.836 37.864C388.7 37.864 392.284 36.808 394.588 34.696C396.892 32.584 398.044 29.576 398.044 25.672C398.044 17.416 393.308 13.288 383.836 13.288H370.972V37.864H383.836Z" stroke={props.stroke_color1} strokeWidth={props.stroke_size} mask="url(#path-1-outside-1)"/>
            </svg>      
        </div>
    )
}

export default Sandeep_animation;